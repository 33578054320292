.pricing-list-table,
.pricing-list-table tr{
    border-bottom: 1px var(--border-color) solid !important;
    text-align: left;
    font-size: 14px;
    margin-top: 80px;
    position: relative !important;
}

.pricing-list-table tr td {
    padding-right: 2%;
}

.pricing-list-table tr th {
    padding-bottom: 1%;
}

.pricing-product-title {
    font-weight: bold;
    text-align: left !important;
}

.pricing-p-col {
    width: 27% !important;
}

.product-price {
    font-weight: bold;
}

.pricing-plan-content-header .p-icon {
    color: gray !important;
}

.pricing-plan-content {
    text-align: center !important;
    padding: 0px !important;
    margin-right: 2%;
}

.pricing-card-button {
    font-size: 16px;
    padding: 5px 10px;
    text-align: center !important;
    color: var(--fixed-color) !important;
    background: #030b27 !important;
    border: 0.590546px solid var(--border-color) !important;
    border-radius: 5px;
}

.pricing-card-button a {
    color: var(--fixed-color) !important;
}