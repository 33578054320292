.policy-list-container h5 {
    font-weight: 600 !important;
}

.policy-list-container ol {
    font-weight: 600 !important;
}

.policy-ul-container {
    line-height: 2 !important;
}

.policy-ul-container li {
    font-weight: 400 !important;
    list-style-type: circle !important;
}