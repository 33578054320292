.footer-list-container {
  margin-left: 3% !important;
  margin-top: 2% !important;
}

#copyright li {
  color: var(--footer-link-color);
}

.footer-list ul h5 {
  font-weight: 700;
  font-size: 14px !important;
}

.footer-list ul li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 39px;
  color: var(--accent-color) !important
}

.footer-list ul li a:hover {
  color: var(--footer-link-color) !important;
}

.footer-logo-box {
  border-top: 1px solid #5251514c !important;
  border-bottom: 1px solid #5251514c !important;
  padding: 5px;
  /* margin: 2%; */
}

.footer-logo-img {
  height: 50px;
  border-radius: 0px;
  margin-right: 15px;
}

.footer-ms-img {
  height: 90px !important;
  border-radius: 0px;
  margin-right: 15px;
}

.footer-logo-list {
  margin-top: 1.2%;
}

.footer-logo-list ul {
  list-style: none;
  margin: 0;
}

.footer-logo-list span {
  font-weight: 700;
  font-size: 20px;
  line-height: 35px;
  margin-right: 9%;
}

.footer-logo-list ul span:hover {
  color: var(--footer-link-color) !important;
}

.footer-logo-list ul li {
  display: inline-block;
  margin-right: 3%;
  font-size: 13px;
}

.footer-logo-list a {
  color: var(--accent-color) !important
}

.footer-logo-list ul li .prime-icon {
  font-size: 13px;
  margin: 4px 12px 0 0;
}

.footer-policy-list ul {
  list-style: none;
  width: 395px;
  text-align: left;
}

.footer-policy-list ul span {
  font-weight: 700;
  font-size: 20px;
  line-height: 35px;
  margin-right: 9% !important;
}

.footer-policy-list ul li {
  display: inline-block;
  margin-right: 3%;
}

.footer-policy-list ul li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--footer-link-color) !important;
}

#copyright li {
  font-size: 16px;
}

.footer-policy-list ul li a:hover {
  color: var(--accent-color) !important;
}

.light-mode-footer-body {
  background-color: var(--background-color) !important;
  color: var(--accent-color) !important
}

.text-center {
  text-align: left !important;
}

.transparent-footer {
  z-index: 99 !important;
  opacity: 1 !important;
}

.transparent-footer .footer-scrolled {
  z-index: 99 !important;
  opacity: 0.7 !important;
}