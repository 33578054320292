.product-page {
    font-family: Verdana (sans-serif) !important;
}

.product-icons {
    width: 35px;
    height: 35px;
}

.product-know {
    padding: 48px !important;
}

.product-name {
    font-family: Verdana (sans-serif);
    font-size: 26px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0px !important;
    padding-left: 10px;
}

.product-know-background {
    background: var(--navbar-background-color) !important;
    backdrop-filter: blur(5px) !important;
}

.product-page-background {
    background: linear-gradient(180deg, #000000 0%, #121318 100%),
        linear-gradient(161.01deg, rgba(255, 255, 255, 0.15) 10.53%, rgba(255, 255, 255, 0) 94.48%);
    color: white !important;
    border: 1.18px solid;
    border-image-source: linear-gradient(161.01deg, rgba(255, 255, 255, 0.15) 10.53%, rgba(255, 255, 255, 0) 94.48%);
    box-shadow: 0px 11.810927391052246px 53.149169921875px 0px rgba(0, 0, 0, 0.35);
}

.product-page-features-background {
    background: linear-gradient(180deg, #000000 0%, #121318 100%),
        linear-gradient(161.01deg, rgba(255, 255, 255, 0.15) 10.53%, rgba(255, 255, 255, 0) 94.48%);
    color: white !important;
    border: 1.18px solid;
    border-image-source: linear-gradient(161.01deg, rgba(255, 255, 255, 0.15) 10.53%, rgba(255, 255, 255, 0) 94.48%);
    box-shadow: 0px 11.810927391052246px 53.149169921875px 0px rgba(0, 0, 0, 0.35);
}

.certified-image {
    height: 20px;
    margin: 6px;
}

.product-know-button {
    display: flex !important;
    justify-content: space-between !important;
    width: 100% !important;
}

.product-know-button a {
    background-color: #030b27 !important;
    border: 1px solid #ffffff36;
    border-radius: 5px !important;
    text-align: center !important;
    font-size: 18px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 20px !important;
}

.product-know-button Button span {
    font-weight: 400 !important;
}

.first-section-margin-top-for-navbar {
    margin-top: 9% !important;
}

.description-font-size {
    font-size: 16px !important;
}

.description-font-size span {
    font-family: Verdana (sans-serif) !important;
    font-size: 16px !important;
}

.spfx-note {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 10px !important;
    font-size: 20px !important;
}

.product-image {
    width: 100% !important;
    cursor: pointer !important;
}

.product-mockup {
    position: relative !important;
    display: flex !important;
    justify-self: center !important;
    align-items: center !important;
}

.product-mockup img {
    width: 100% !important;
    height: auto !important;
}

.price-product-icon {
    width: 40px !important;
    height: 40px !important;
    padding: 5px !important;
}

.price-badge-icon {
    width: 20px !important;
    height: 20px !important;
    margin-left: 5px !important;
}

.product-description-color span {
    color: var(--accent-color) !important;
}

@media(min-width:320px) and (max-width:499px) {
    .product-icons {
        width: 25px !important;
        height: 25px !important;
    }

    .product-name {
        font-family: Verdana (sans-serif);
        font-size: 13px;
        font-weight: 700;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: center;
        margin: 0px !important;
        padding-left: 10px;
    }

    .product-know {
        flex-direction: column-reverse !important;
        font-size: 12px !important;
        padding: 25px !important;
    }

    .product-know-button a {
        padding: 10px !important;
    }

    .description-font-size {
        text-align: justify;
        font-size: 12px !important;
    }

    .description-font-size span {
        text-align: justify;
        font-size: 12px !important;
    }

    .product-page-background {
        padding: 5px !important;
    }

    .certified-image {
        height: 20px;
        margin: 6px;
    }

    .product-know-button span {
        font-weight: 400 !important;
    }

    .product-know-button a {
        font-size: 10px !important;
    }

    .product-point span {
        font-size: 12px !important;
    }

    .spfx-note {
        text-align: justify;
        font-size: 12px !important;
    }

    .first-section-margin-top-for-navbar {
        margin-top: 25% !important;
    }

    .certified h5 {
        font-size: 18px !important;
    }

    .price-badge-icon {
        width: 15px !important;
        height: 15px !important;
        margin-left: 5px !important;
    }
}

@media(min-width:499px) and (max-width:768px) {
    .product-icons {
        width: 45px;
        height: 45px;
    }

    .product-name {
        font-family: Verdana (sans-serif);
        font-size: 24px;
        font-weight: 700;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: center;
        margin: 0px !important;
        padding-left: 10px;
    }

    .product-know {
        padding: 25px !important;
        flex-direction: column-reverse !important;
    }

    .certified-image {
        height: 20px;
        margin: 6px;
    }

    .product-know-button span {
        font-weight: 400 !important;
    }

    .product-know-button a {
        font-size: 15px !important;
    }

    .product-point span {
        font-size: 15px !important;
    }

    .first-section-margin-top-for-navbar {
        margin-top: 10% !important;
    }

    .description-font-size {
        font-size: 14px !important;
    }
}