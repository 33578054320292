.service-icon-container-lm {
    background-image: url('../../../../src/assets/images/careerSharepointempower/career-background.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 15px;
    margin: 2% 6% 0 !important;
}

.service-icon-box-lm {
    backdrop-filter: blur(7px);
    background: linear-gradient(180deg, #000000 0%, #121318 100%);
    box-shadow: 0px 11.8109px 53.1492px rgb(0 0 0 / 35%);
    border: 1.18px solid rgb(255 255 255 / 10%);
    padding-block: 14%;
    width: 100%;
    height: 100%;
}

.product-management-icon img {
    height: 50px;
}

.icon-card-span {
    color: var(--fixed-color);
    font-weight: 300;
    font-size: 18px;
    line-height: 220%;
}