.product-guide-container {
    padding-top: 15px !important;
    color: var(--fixed-color) !important;
    background: transparent !important;
    box-shadow: 0px 11.8109px 53.1492px rgba(0, 0, 0, 0.35);
    border: 1.18px solid rgba(255, 255, 255, 10%);
}

.suggestions {
    position: absolute !important;
    left: 0;
    background-color: #ffffff;
    color: black !important;
    cursor: pointer !important;
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 8px 0;
    z-index: 1;
    max-height: 250px !important;
    overflow: auto !important;
}

.suggestions li {
    padding: 8px 16px;
    cursor: pointer !important;
    transition: background-color 0.3s ease;
    font-size: 14px !important;
}

.suggestions li:hover {
    background: var(--hover-effect-color) !important;
    color: var(--fixed-color) !important;
    cursor: pointer !important;
}

.selected {
    background-color: var(--hover-effect-color) !important;
    color: var(--fixed-color) !important;
}

.product-guide-inner-container a {
    text-decoration: none !important;
    color: var(--accent-color) !important;
    cursor: pointer !important;
}

.product-guide-inner-container {
    overflow-y: auto !important;
    position: relative;
    height: calc(100vh - 100px) !important;
}

.help-para {
    color: var(--accent-color) !important;
}

.loading-spinner {
    background-color: var(--background-color) !important;
}

.help-para div span,
.help-para div p,
.help-para div h1,
.help-para div h4,
.help-para div h5 {
    font-family: Verdana (sans-serif) !important;
    font-size: 14px !important;
    line-height: 2 !important;
}

.description-top-scroll {
    background-color: var(--accent-color) !important;
    border-radius: 50px !important;
    left: 100% !important;
    margin: 0px !important;
    z-index: 99 !important;
}

.description-top-scroll span {
    color: var(--background-color) !important;
}

.p-galleria-close {
    z-index: 999 !important;
    position: fixed !important;
}

.p-galleria-mask {
    overflow: auto !important;
}

.p-galleria-footer {
    position: fixed !important;
    bottom: 0 !important;
    right: 0 !important;
    left: 0 !important;
    background: #19191954 !important;
    backdrop-filter: blur(1px) !important;
}

.p-galleria-mask::-webkit-scrollbar-thumb {
    background-color: white !important;
}

.p-galleria-mask .p-galleria-item-nav {
    position: sticky !important;
    top: 50% !important;
    bottom: 50% !important;
    margin-top: -.5rem !important;
    z-index: 99 !important;
}

.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon.p-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon.p-icon {
    width: 2rem;
    height: 2rem;
    color: #a3a3a3 !important;
}

.breadcrumb-section {
    background: var(--navbar-background-color) !important;
    backdrop-filter: blur(10px) !important;
    border: none !important;
    display: flex;
    justify-content: space-between !important;
    position: sticky !important;
    top: 0 !important;
}

.next-button-container {
    display: flex !important;
    align-items: center !important;
}

.next-prev-button {
    padding: 1px !important;
    display: flex !important;
    justify-content: center !important;
}

.next-prev-button button {
    background-color: gray !important;
    border: none !important;
    width: 25px !important;
    height: 0px !important;
    padding: 13px !important;
    display: flex;
    justify-content: center;
}

.next-prev-button button:focus {
    box-shadow: none !important;
}

.next-prev-button button i {
    color: var(--accent-color) !important;
    font-size: 13px !important;
}

/* Styles for Breadcrumb */
svg.p-icon.p-breadcrumb-chevron {
    color: var(--accent-color) !important;
}

.p-breadcrumb {
    background: transparent !important;
    border: none !important;
    padding-left: 4px !important;
}

.p-breadcrumb li:last-child {
    font-weight: 600;
}

.mobile-toggle {
    display: none;
}

.custom-submenu {
    color: var(--accent-color) !important;
    background-color: transparent !important;
}

.product-guide-container .css-ewdv3l li a {
    color: var(--accent-color) !important;
    height: 34px !important;
}

.custom-submenu .ps-submenu-content {
    background-color: var(--background-color) !important;
    color: var(--accent-color) !important;
}

.product-guide-container .css-16jesut>.ps-menu-button:hover {
    background-color: #030b27 !important;
    color: white !important;
}

.product-guide-container .css-1tqrhto>.ps-menu-button:hover {
    background-color: #030b27 !important;
    color: white !important;
}

.product-guide-container .css-dip3t8 {
    position: relative;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 3;
    background-color: var(--background-color) !important;
}

.custom-submenu .ps-submenu-content ul {
    overflow: auto !important;
}

.toggle-button {
    background: transparent !important;
    border: none !important;
}

.product-guide-container .css-12w9als {
    font-size: 13px !important;
}

.toggle-button i {
    color: var(--accent-color) !important;
    font-size: 24px !important;
}

.selected-item-active {
    background-color: #030b27 !important;
    color: white !important;
}

.selected-item-active span {
    background-color: #030b27 !important;
    color: white !important;
}

.faq-button-container {
    display: flex !important;
    justify-content: center !important;
    position: sticky !important;
    bottom: 0 !important;
    border-top: 1px solid gray !important;
    background-color: var(--background-color) !important;
    padding: 5px !important;
    color: var(--accent-color) !important;
}

.product-guide-container .css-14xmt93>.ps-menu-button:hover,
.product-guide-container .css-1rzjm54>.ps-menu-button:hover,
.product-guide-container .css-1ffbfs>.ps-menu-button:hover,
.product-guide-container .css-1t8x7v1>.ps-menu-button:hover,
.product-guide-container .css-1654oxy>.ps-menu-button:hover {
    background-color: #030b27 !important;
    color: white !important;
}

.faq-button-container:hover {
    background-color: #030b27 !important;
    cursor: pointer !important;
    color: white !important;
}

.faq-button-container i {
    color: gray !important;
}

.product-guide-container .css-1jx5vff {
    position: relative;
    border: 0px !important;
    -webkit-transition: width, left, right, 500ms;
    transition: width, left, right, 500ms;
    width: 260px;
    min-width: 260px;
}

.product-guide-container {
    border-bottom: 1px solid gray !important;
}

.css-1ffbfs>.ps-menu-button,
.css-14xmt93>.ps-menu-button,
.css-1rzjm54>.ps-menu-button {
    padding-left: 65px !important;
}

.p-breadcrumb-list {
    font-size: 13px !important;
}

.sidebar-component .css-um1o6k {
    display: none !important;
}

.sidebar-component {
    border-right: 1px solid gray !important;
}

.light-mode-title span,
b {
    color: var(--accent-color) !important;
}

@media (min-width: 325px) and (max-width: 768px) {

    .css-1wvake5.ps-collapsed {
        width: 0px !important;
        min-width: 0px !important;
    }

}

@media (max-width: 992px) {

    .product-guide-container .css-1jx5vff.ps-collapsed {
        width: 0px !important;
        min-width: 0px !important;
    }

}