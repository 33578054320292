#banner-video {
    -o-object-fit: cover;
    object-fit: cover;
}

.banner-container {
    width: 100%;
    height: 100vh;
    position: relative;
    background: black !important;
}

.banner-video-mask {
    opacity: 0.7 !important;
}

.microsoft-icon-banner-video-mask {
    opacity: 1 !important;
}

.microsoft-icon-banner-video-mask.scrolled-banner {
    opacity: 0.2 !important;
}

.banner-video-mask.scrolled-banner {
    opacity: 0.2 !important;
}

.loading-image {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100vh !important;
}

/* 
.cookie-row {
    position: fixed !important;
    bottom: 0 !important;
    right: 0 !important;
    z-index: 1 !important;
    display: flex !important;
    justify-content: end !important;
    margin: 0 1% 1% 0 !important;
} */

/* 
#chatgpt {
    position: fixed !important;
    bottom: 0 !important;
    right: 0 !important;
    z-index: 1 !important;
    padding: 30px !important;
} */

/* 
.modal-dialog {
    max-width: none !important;
}

.cookie-text {
    margin: 1% !important;
} */

/* .cookie-container {
    height: 12% !important;
    width: 60% !important;
    border-radius: 0 !important;
    color: var(--fixed-color) !important;
    background: var(--fixed-background-color) !important;
} */

/* .btn-cookie-custom-allow,
.btn-cookie-custom-decline {
    border-radius: 0 !important;
    font-size: 19px !important;
    padding: 20px !important;
    font-weight: 400 !important;
    margin: 0 !important;
} */

/* .btn-cookie-custom-allow {
    background-color: var(--fixed-background-color) !important;
    color: var(--fixed-color) !important;
    border-color: var(--fixed-color) !important;
    border-radius: 50px !important;
}

.btn-cookie-custom-decline {
    background-color: var(--fixed-color) !important;
    color: var(--fixed-background-color) !important;
    border-color: var(--fixed-background-color) !important;
    border: 1px solid black !important;
    border-radius: 50px !important;
} */

/* .btn-cookie-custom-allow:hover {
    background-color: var(--fixed-color) !important;
    color: var(--fixed-background-color) !important;
    border-color: var(--fixed-background-color) !important;
}

.btn-cookie-custom-allow {
    background-color: var(--fixed-background-color) !important;
    color: var(--fixed-color) !important;
    border-color: var(--fixed-color) !important;
}

.btn-cookie-custom-decline {
    background-color: var(--fixed-background-color) !important;
    color: var(--fixed-color) !important;
    border-color: var(--fixed-color) !important;
}

.btn-cookie-custom-decline:hover {
    border-color: var(--fixed-color) !important;
    background-color: var(--fixed-color) !important;
    color: var(--fixed-background-color) !important;
} */