.job-apply-button a {
    color: white !important;
    background-color: #030b27 !important;
    text-align: center !important;
    border-radius: 5px !important;
}

@media (max-width:498px) {
    .job-position p {
        font-size: 14px !important;
    }

    .job-position h4 {
        font-size: 18px !important;
    }
}