.contact-product-dropdown {
  top: 400.812px !important;
}

.contact-us-product-select {
  width: 100% !important;
  background: none !important;
}

.contact-us-success-modal {
  opacity: 0.9;
  z-index: 9999 !important;
  justify-content: center !important;
}

.contact-us-success-modal .p-dialog-header {
  display: none !important;
}

.contact-us-success-modal .p-dialog-content {
  padding: 0 !important;
  overflow-y: hidden !important;
}

.contact-us-form {
  padding: 2%;
  color: var(--accent-color) !important;
}

.contact-us-form label {
  font-weight: 500;
  font-size: 16px !important;
  line-height: 130%;
  margin: 4% 0 0 0;
  color: var(--accent-color) !important;
}

.quotation-checkbox mark {
  background-color: #fbfb74 !important;
}

textarea.form-control {
  color: var(--accent-color) !important;
}

.form-control {
  border-bottom: 1px solid var(--inverted-background-color)
}

.sent-mail-alert-image {
  width: 100% !important;
  height: 100% !important;
}