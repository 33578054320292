.about-us-inner-card-body:hover {
    border: 1.19px solid rgba(255, 255, 255, 0.603);
}

.about-us-inner-card-title {
    font-weight: 700;
    font-size: 45px !important;
    line-height: 1.5;
    text-align: center;
    position: absolute;
    top: 10%;
}

.about-us-inner-card-title .pi {
    font-size: 3rem !important;
}

.about-us-inner-card-subtitle {
    font-weight: 400;
    font-size: 24px;
    line-height: 1.5;
    text-align: center;
    position: absolute;
    top: 27% !important;
}

.about-us-inner-card-content {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    position: absolute;
    top: 37%;
    right: 0%;
    padding: 0px 20px;
}

.about-gallery-section {
    background: linear-gradient(180deg, #000000 0%, #121318 100%);
    box-shadow: 0px 11.8109px 53.1492px rgba(0, 0, 0, 0.35);
    border: 1.18px solid;
    border-image-source: linear-gradient(161.01deg,
            rgba(255, 255, 255, 0.15) 10.53%,
            rgba(255, 255, 255, 0) 94.48%);
}

.about-us-feature-icon ul li {
    margin: 100%;
}

.about-us-inner-card-container {
    display: flex;
    justify-content: center;
    margin: 2%;
    margin-left: 2%;
}

.about-us-inner-card-body {
    position: relative;
    width: 100%;
    height: 415px;
    color: var(--fixed-color);
    background: linear-gradient(180deg, #000000 0%, #121318 100%);
    box-shadow: 0px 11.8109px 53.1492px rgba(0, 0, 0, 0.35);
    border: 1.18px solid;
    border-image-source: linear-gradient(161.01deg,
            rgba(255, 255, 255, 0.15) 10.53%,
            rgba(255, 255, 255, 0) 94.48%);
    overflow: auto !important;
}

.about-us-banner-container {
    position: relative;
    width: 100%;
    height: 70vh;
    overflow: hidden;
}

.about-us-banner-image {
    background-image: url("../../../assets/images/aboutUs/about-us-background.jpeg"); 
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-us-banner-text-overlay {
    position: absolute;
    bottom: 0%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 20px;
    text-align: center;
    border-radius: 5px;
}

.about-us-banner-text {
    font-size: 1.2rem;
    line-height: 1.5;
    font-weight: 300;
}
