.payment-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100vh !important;
    flex-direction: column !important;
}

.payment-status-container {
    display: flex !important;
    justify-content: center !important;
    background-color: var(--background-color) !important;
    max-width: 500px;
    position: relative !important;
    margin: 0 auto;
    border: 1px solid rgba(128, 128, 128, 0.621);
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.payment-success p,
.auth-failed p,
.authorization-failed p,
.authentication-failed p {
    font-size: 13px !important;
}

.auth-failed .pi,
.payment-pending .pi,
.verification-required .pi,
.authentication-failed .pi,
.authorization-failed .pi {
    font-size: 2.5rem !important;
}

.payment-success,
.payment-pending,
.verification-required,
.auth-failed,
.authorization-failed,
.authentication-failed {
    padding: 20px;
}

.success-icon {
    font-size: 50px;
    color: green;
    margin-bottom: 10px;
}

.pi-check-circle,
.pi-clock,
.pi-lock {
    color: #0F9D58 !important;
    font-size: 40px !important;
}

.payment-container h2 {
    color: green !important;
    margin-bottom: 20px !important;
}

.download-button {
    padding: 10px 20px;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.download-button:hover {
    background-color: darkblue;
}

.payment-details {
    width: 100%;
}

.details-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
}

.details-table td {
    padding: 10px 0;
    font-size: 15px;
    vertical-align: top;
}

.details-table td:first-child {
    text-align: left;
    font-weight: bold;
    width: 40%;
}

.details-table td:last-child {
    text-align: left;
    width: 60%;
}

.status-title {
    font-weight: 600 !important;
    color: #0F9D58 !important;
    padding: 10px !important;
}

.invoicebtn .p-button {
    background: #030b27 !important;
    border: 1px solid gray !important;
}

.auth-failed h2,
.authorization-failed h2,
.authentication-failed h2 {
    color: #DB4437 !important;
    font-size: 26px !important;
}

.close-button {
    position: absolute;
    right: 10px !important;
    top: 10px !important;
    background: transparent;
    border: none;
    color: gray !important;
    font-size: 24px;
    cursor: pointer;
    line-height: 1;
}

.redirect-timer {
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
    color: var(--accent-color) !important;
}