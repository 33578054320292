.mobile-background-banner {
    position: absolute !important;
    width: 100% !important;
    height: 100vh !important;
    object-fit: cover !important;
    opacity: 0.5 !important;
}

.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: black !important;
}