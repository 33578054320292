.accordion-button,
.accordion-item {
    color: var(--background-color) !important;
    background-color: var(--accent-color) !important;
    z-index: 0 !important;
}

.accordion-item h2 {
    margin-bottom: 0% !important;
}

.accordion-item .accordion-header button {
    background-color: var(--background-color) !important;
}

.accordion-header {
    margin-top: 0px !important;
}

.accordion-button:focus {
    box-shadow: none !important;
}

.accordion-body {
    background-color: var(--background-color) !important;
    color: var(--accent-color) !important;
}

.accordion-button {
    color: var(--accent-color) !important;
}

.accordion-body ol li b {
    color: var(--accent-color) !important;
}