.all-product-page {
    position: relative !important;
    z-index: 99 !important;
}

.all-product-icon {
    width: 35px;
    height: 35px;
}

.p-card-content {
    padding: 0 !important;
}

.product-quotation-button a {
    color: var(--accent-color) !important;
}

.card-background-image {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20% !important;
    height: 23% !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.1;
    margin: 5px !important;
}

@media(min-width:320px) and (max-width:499px) {
    .all-product-icon {
        width: 30px;
        height: 30px;
    }

    .card-background-image {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 60px !important;
        height: 60px !important;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0.1;
    }

    .all-first-section-margin-top-for-navbar {
        margin-top: 6% !important;
        font-size: 18px !important;
    }

    .product-quotation-button a {
        margin-top: 18px !important;
        display: flex;
        align-items: center !important;
    }

}

@media(min-width:499px) and (max-width:768px) {
    .all-product-icon {
        width: 45px;
        height: 45px;
    }

    .product-point span {
        font-size: 15px !important;
    }

    .all-first-section-margin-top-for-navbar {
        margin-top: 6% !important;
    }

    .card-background-image {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 50px !important;
        height: 50px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0.1;
        margin: 5px !important;
    }

    .p-card .p-card-body {
        padding: 1rem;
    }
}

@media(min-width:768px) and (max-width:1024px) {

    .card-background-image {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 60px !important;
        height: 60px !important;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0.1;
        margin: 5px !important;
    }

    .p-card .p-card-body {
        padding: 1.2rem;
    }

    .fixed-top-products-tab {
        position: sticky !important;
        top: 77px;
        left: 0;
        right: 0;
        background-color: var(--background-color) !important;
        z-index: 1;
        width: 100%;
    }

}