body {
  --background-color: #000000 !important;
  --accent-color: #ffffff !important;
  --navbar-background-color: rgb(0 0 0 / 39%) !important;
  --faq-footer-border-color: #7d7d7d !important;
  --inverted--color: var(--background-color) !important;
  --inverted-background-color: var(--accent-color) !important;
  --fixed-color: #fff !important;
  --fixed-background-color: #000 !important;
  --hover-effect-color: #030b27 !important;
  --transition-delay: 0.5s !important;
  --footer-link-color: #9a9ea5 !important;
  scroll-behavior: smooth !important;
  --rs-sidenav-default-bg: #000 !important;
  --bs-table-bg: transparent !important;
  --border-color: #ffffff36;

  margin: 0 auto !important;
  padding: 0 !important;
  overflow-x: hidden !important;
  font-family: Verdana (sans-serif) !important;
  font-style: normal !important;
  color: var(--accent-color) !important;
  background-color: var(--background-color) !important;
  transition: var(--transition-delay) !important;

  position: relative;
  min-height: 100% !important;
  top: 0px !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;


  /* Hide the scrollbar for webkit browsers (Chrome, Safari, etc.) */
  &::-webkit-scrollbar {
    display: none !important;
  }
}

.dark-mode-body {
  --background-color: #ffffff !important;
  --accent-color: #000000 !important;
  --border-color: #00000036;
  --transition-delay: 0.5s !important;
  transition: var(--transition-delay) !important;
  --navbar-background-color: rgba(255, 255, 255, 0.389) !important;
}

.content-div {
  position: relative !important;
  top: 80px !important;
}

p {
  margin-top: 8px !important;
}

ul {
  list-style: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

a {
  text-decoration: none !important;
  color: var(--fixed-color) !important;
  cursor: pointer !important;
}

a:active {
  color: var(--fixed-color) !important;
}

.h1-tag-lm {
  font-weight: 700 !important;
  font-size: 28px;
}

.h2-subtitle-lm {
  font-style: italic;
  font-weight: 400;
  font-size: 35px;
  line-height: 130%;
}

.h5-title-lm {
  font-weight: 700;
  font-size: 35px;
  line-height: 54px;
}

.p-content-lm {
  font-size: 17px;
  line-height: 1.5;
  margin-top: 40px;
}

.p-center-content-lm {
  text-align: center;
  font-size: 17px;
  line-height: 1.5;
  margin-top: 40px;
}

.p-justify-content-lm {
  text-align: justify;
  font-size: 17px;
  line-height: 1.5;
  margin-top: 40px;
}

.p-guide-content-lm {
  font-weight: 300;
  font-size: 14px;
  line-height: 130%;
}

.p-cookie-content-lm {
  font-weight: 300;
  font-size: 18px;
}

.container-fluid {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.react-multiple-carousel__arrow {
  z-index: 0 !important;
}

.p-icon {
  color: white !important;
}

.button-gradient {
  text-align: center;
  padding: 7px 35px;
  font-weight: 500;
  font-size: 19px;
  line-height: 27px;
  background: linear-gradient(111.56deg,
      rgba(114 114 114 / 43%) -2.6%,
      rgba(255, 255, 255, 0.17) 89.17%);
  border: 1px solid var(--border-color);
  backdrop-filter: blur(27px);
  color: var(--accent-color) !important;
  border-radius: 30px;
}

.button-gradient:hover {
  background: var(--hover-effect-color);
  color: white !important;
  transition: 0.5s ease;
}

.light-mode-service-subtract-bg,
.dark-mode-footer-subtract-bg {
  background-image: url("assets/images/sharepointempowerBackground/dark-mode-subtract-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  z-index: -1;
  width: 100%;
  position: absolute;
}

.card {
  border: none !important;
}

.faq-button a {
  border: 1px solid var(--border-color) !important;
  display: flex !important;
  align-items: center !important;
  padding: 5px 10px 5px 10px !important;
  border-radius: 2px !important;
}

.pricing-quotation {
  border: 1px solid var(--border-color) !important;
  display: flex !important;
  align-items: center !important;
  padding: 5px 10px 5px 10px !important;
  border-radius: 2px !important;
}

table td,
table th,
table th a,
table td a {
  border: none;
  font-size: 16px;
  color: var(--accent-color) !important;
  background-color: transparent !important;
}

.table {
  --bs-table-color: var(--accent-color) !important;
  --bs-table-bg: transparent !important;
}

.form-container {
  padding-top: 15px !important;
  color: var(--fixed-color) !important;
  background: transparent !important;
  box-shadow: 0px 11.8109px 53.1492px rgba(0, 0, 0, 0.35);
  border: 1.18px solid rgba(255, 255, 255, 10%);
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 5px;
  cursor: pointer !important;
}

::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.p-dropdown-items-wrapper::-webkit-scrollbar-thumb {
  background-color: black !important;
}

::-webkit-scrollbar-thumb {
  background-color: var(--accent-color) !important;
  opacity: 0.5 !important;
  border-radius: 20px;
  border: 1px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

input[type="text"],
textarea {
  width: 100%;
  padding: 8px 5px 8px 5px;
  margin: 5px 0;
  box-sizing: border-box;
  border: 1px solid gray !important;
  border-radius: 5px !important;
  background: transparent !important;
  border-bottom: 1px solid var(--inverted-background-color);
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
  color: var(--accent-color) !important;
}

.p-dropdown-filter-container .p-dropdown-filter {
  font-size: 15px !important;
  padding: 10px !important;
}

input[type="text"]:focus,
input[type="text"]:visited {
  color: var(--accent-color) !important;
}

.contact-mail,
.form-dropdown {
  color: var(--accent-color) !important;
}

.text-area {
  background-color: hsla(0, 0%, 100%, 0) !important;
  border: 1px solid gray !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin-top: 6px;
}

.form-img-container {
  display: flex;
  justify-content: center;
}

.form-img-container img {
  height: auto !important;
}

.faq-button i,
.faq-button span {
  color: var(--accent-color) !important;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modal-content {
  display: block;
  max-width: 43%;
  border: 1px solid rgba(255, 255, 255, 0.216) !important;
}

.modal-content {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
  border-radius: 0 !important;
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.p-dialog-header-close-icon {
  color: black !important;
}

.p-dialog-header-maximize {
  background-color: rgba(0, 0, 0, 0.352) !important;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border-color: none !important;
}

.p-skeleton {
  background-color: #dee2e636 !important;
  border-radius: 6px !important;
}

.fixed-top-container {
  position: sticky;
  top: 77px;
  left: 0;
  right: 0;
  background-color: transparent !important;
  z-index: 1;
  width: 100%;
  backdrop-filter: blur(5px) !important;
}

.p-multiselect .p-multiselect-label {
  padding: 0.42rem 0.45rem !important;
}

.p-multiselect-items-wrapper .p-multiselect-item {
  padding: 0.40rem 1.25rem !important;
  font-size: 14px !important;
}

svg.p-icon.p-multiselect-trigger-icon.p-c,
svg.p-icon.p-dialog-header-close-icon {
  color: var(--accent-color) !important;
}

.p-dialog .p-dialog-header {
  background-color: var(--background-color) !important;
}

.p-multiselect:not(.p-disabled):hover {
  border-color: gray !important;
}

.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: none !important;
  border-color: gray !important;
}

.p-dialog-mask {
  backdrop-filter: blur(5px) !important;
}

.content-subtitle {
  margin-top: 20px;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--accent-color) !important;
  background: #030b27 !important;
}