.megamenu-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed !important;
    z-index: 999;
    right: 0;
    left: 0;
    top: 0;
}

.megamenu-container .p-component {
    font-family: Verdana (sans-serif) !important;
}

.logo {
    display: flex;
    align-items: center;
}

.logo svg {
    height: 40px;
}

.p-megamenu {
    border: none !important;
    flex-grow: 1;
    justify-content: inherit;
    background-color: transparent !important;
    border-radius: 0 !important;
}

.p-megamenu .p-menuitem-link {
    padding: 0.5rem 1rem;
}

.megamenu-container .p-menuitem-text {
    display: none !important;
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover {
    background: none !important;
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
}

.p-megamenu .p-menuitem.p-menuitem-active>.p-menuitem-link {
    background: none !important;
}

.p-megamenu-submenu .p-menuitem {
    padding: 15px !important;
}

.p-menuitem {
    padding: 0 !important;
}

.p-megamenu-panel {
    position: absolute !important;
    right: -200px !important;
    width: 37vw !important;
}

.company .p-megamenu-panel {
    background: var(--background-color) !important;
    -webkit-backdrop-filter: blur(10px) !important;
    backdrop-filter: blur(10px) !important;
    right: 12% !important;
    width: 435% !important;
}


.megamenu-content {
    display: flex;
    justify-content: space-between;
}

.megamenu-column {
    width: 48%;
}

.microsoft-badge {
    width: 20px;
}

.p-megamenu .p-megamenu-submenu {
    padding: 0.25rem 0;
    width: fit-content !important;
}

.logo {
    width: 295px;
    left: 15px;
    top: 2px;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list {
    background: #000;
}

.p-megamenu .p-megamenu-button:hover {
    background: #000;
}

.p-megamenu .p-megamenu-button:focus {
    box-shadow: none !important;
}

.p-megamenu-horizontal .p-megamenu-root-list {
    align-items: normal !important;
}

.megamenu-container .p-megamenu-horizontal {
    align-items: self-start !important;
}

.megamenu-container .p-megamenu {
    height: 5rem !important;
}


.p-megamenu-start .logo {
    width: 250px !important;
}

.p-megamenu .p-megamenu-submenu-header {
    padding: 0;
}

.p-megamenu-grid ul li a {
    font-family: Verdana (sans-serif);
    font-style: normal;
    color: var(--accent-color) !important;
    font-size: 18px;
    line-height: 36px;
}

.p-megamenu-grid {
    padding: 14px 2.5rem 2.5rem !important;
}

.transparent .p-megamenu ul li a {
    font-family: Verdana (sans-serif);
    font-style: normal;
    font-size: 14px !important;
    line-height: 36px;
    color: white !important;
}

.transparent .p-megamenu .company ul li a span {
    color: var(--accent-color) !important;
}

.transparent .p-megamenu ul li a svg {
    color: white !important;
}

.p-megamenu ul li a {
    font-family: Verdana (sans-serif);
    font-style: normal;
    font-size: 18px;
    line-height: 36px;
    color: var(--accent-color) !important;
}

.p-megamenu ul li a svg {
    color: var(--accent-color) !important;
}

.p-megamenu-grid ul li a {
    font-family: Verdana (sans-serif);
    font-style: normal;
    font-size: 14px;
    line-height: 36px;
    font-weight: 700;
}

.p-megamenu-grid ul li a .align-items-start span {
    font-weight: 700;
}

.p-megamenu-grid ul li a .subtext {
    font-size: 14px !important;
}

.p-megamenu-root-list .header-font-bold {
    font-weight: 600 !important;
    font-size: 16px !important;
}

.p-megamenu ul li a .p-icon,
.nav-link {
    font-weight: 600 !important;
    font-size: 16px !important;
}

.company .p-megamenu-grid {
    padding: 1.5rem !important;
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:focus {
    box-shadow: none !important;
}

.transparent {
    background-color: none !important;
    transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
    color: white !important;
}

.blurred {
    background: var(--navbar-background-color) !important;
    backdrop-filter: blur(10px);
    transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
    color: var(--accent-color) !important;
}

.scrolled {
    background: var(--navbar-background-color) !important;
    backdrop-filter: none;
    transition: background-color 0.3s ease;
}

.megamenu-container.scrolled {
    background: var(--navbar-background-color) !important;
    backdrop-filter: blur(10px);
}

.megamenu-container.scrolled .p-megamenu ul li a {
    color: var(--accent-color) !important
}

.megamenu-container.scrolled .nav-link {
    color: var(--accent-color) !important
}

.megamenu-container.scrolled .p-megamenu .p-icon {
    color: var(--accent-color) !important
}

.product-dropdown:hover .p-megamenu-panel {
    display: block !important;
    opacity: .9;
    transition: opacity 0.3s ease;
}

.company:hover .p-megamenu-panel {
    /* display: block !important; */
    visibility: visible;
    opacity: .9;
    transition: opacity 0.3s ease;
}

.p-megamenu-root-list .get-in-touch .get-in-touch-border {
    border: solid 1px var(--surface-400);
    border-radius: 5px;
    padding: 0px 4px;
}

.company .p-megamenu-submenu .p-menuitem {
    padding: 6px !important;
}

.megamenu-container .microsoft-badge {
    width: 20px !important;
    height: 20px !important;
}

.p-megamenu .p-megamenu-submenu-header {
    padding: 0 !important;
}

.product-dropdown .flex-item-img {
    flex-direction: column;
}

.p-megamenu-panel .product-font {
    position: relative;
    z-index: 1;
    font-weight: bold;
    cursor: pointer;
    font-size: 14px;
}

.product-font::after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background-color: var(--accent-color);
    transition: width 0.8s ease;
    margin-top: 0.5rem;
    position: absolute;
    bottom: -5px;
    left: 0;
}

.flex-item-img:hover .product-font::after {
    width: 100%;
}

.subtexts:hover .product-font::after {
    width: 100%;
}

.flex-item-img:hover {
    cursor: context-menu;
}

.company-menu-underline {
    position: relative;
    display: inline-block !important;
    text-wrap-mode: nowrap !important;
}

.company-menu-underline::after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background-color: var(--accent-color);
    transition: width 0.8s ease;
    position: absolute;
    bottom: -5px;
    left: 0;
}

.company-menu-underline:hover::after {
    width: 100%;
}

.product-dropdown .p-megamenu-panel {
    background: var(--background-color) !important;
    backdrop-filter: blur(10px) !important;
    right: -250% !important;
    width: fit-content !important;
}

.company .icon-size {
    font-size: 21px;
}

.company .p-megamenu-submenu .p-menuitem {
    width: max-content !important;
}

.company .p-megamenu-grid {
    width: auto !important;
}

/*menu Row css*/

.product-dropdown .p-megamenu-submenu {
    height: 10rem;
    display: flex;
}

.product-dropdown .p-megamenu-grid {
    display: block;
}

.product-dropdown .p-megamenu-col-4 {
    width: 100% !important;
}

.product-dropdown .mobile-text {
    width: 21rem;
    color: var(--accent-color) !important;
}


@media screen and (max-width: 968px) {
    .p-megamenu-grid {
        display: block !important;
        padding: 0rem !important;
        margin-left: 0px !important;
        padding-top: 0px !important;
    }

    .p-megamenu .p-megamenu-submenu-header {
        padding: 0 !important;
    }

    .p-menuitem {
        padding: 0 !important;
    }

    .p-megamenu-submenu .p-menuitem {
        padding: 8px !important;
    }

    .p-megamenu .p-megamenu-button {
        margin-top: 11px;
    }

    .company .p-menuitem {
        padding: 0px !important;
    }

    .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:focus {
        box-shadow: none;
    }

    .p-megamenu-root-list {
        margin-top: 0px !important;
    }

    .company .p-megamenu-panel .p-megamenu-grid {
        padding-left: 1rem !important;
    }

    .p-megamenu-root-list .p-megamenu-panel {
        margin-top: 0px !important;
    }

    .company .p-megamenu-submenu .p-menuitem {
        padding: 0px !important;
    }

    .p-megamenu .p-megamenu-button:hover {
        color: #6c757d;
        background: none !important;
    }

    .megamenu-container .p-megamenu-root-list {
        background: var(--background-color) !important
    }

    .megamenu-container .p-megamenu-panel {
        background: var(--background-color) !important
    }

    .transparent .p-megamenu ul li a {
        font-family: Verdana (sans-serif);
        font-style: normal;
        font-size: 18px;
        line-height: 36px;
        color: var(--accent-color) !important;
    }

    .transparent .p-megamenu ul li a svg {
        color: var(--accent-color) !important;
    }

    .p-megamenu ul li a {
        color: var(--accent-color) !important;
    }

    .p-megamenu .nav-link {
        color: var(--accent-color) !important;
    }

    .p-megamenu-root-list .p-icon {
        color: var(--accent-color) !important;
    }

    .megamenu-container .p-megamenu {
        height: 4rem !important;
    }

    .p-megamenu-root-list .get-in-touch .get-in-touch-border {
        padding: 8px 4px;
    }

    .p-megamenu-start .logo {
        width: 200px !important;
    }

    .p-megamenu-panel .product-logo {
        width: 22px !important;
    }

    .p-megamenu-panel .product-font {
        font-size: 14px !important;
    }

    .p-megamenu-grid ul li a .subtext {
        display: none;
        align-items: center !important;
    }

    .p-megamenu[pr_id_1]>.p-megamenu-root-list .p-menuitem-active .p-megamenu-panel {
        position: relative !important;
        left: 0 !important;
        box-shadow: none;
        border-radius: 0;
        background: inherit;
    }

    .megamenu-container .p-megamenu-root-list {
        overflow: auto !important;
        max-height: 21rem !important;
        overflow-y: scroll !important;
        overflow-x: hidden !important;
    }

    .megamenu-container .p-megamenu-root-list::-webkit-scrollbar {
        display: none;
    }

    .p-megamenu-horizontal .p-megamenu-root-list {
        flex-wrap: nowrap !important;
    }

    .product-dropdown .flex-item-img {
        flex-direction: row !important;
        ;
    }

    .company .p-megamenu-grid {
        padding: 0rem !important;
        margin-left: 0px !important;
    }

    .product-dropdown .mobile-text {
        text-wrap: nowrap;
    }

    .product-dropdown .subtext {
        margin-left: 10px;
    }

    .product-dropdown .p-megamenu-submenu {
        display: flex;
        height: auto !important;
        flex-direction: column;
    }

    .p-megamenu-panel {
        width: 100% !important;
    }
}

@media screen and (min-width: 800px) and (max-width: 833px) {
    .p-megamenu-start .logo {
        width: 280px !important;
    }

    .p-megamenu .p-megamenu-button {
        margin-top: 10px;
    }

    .p-megamenu-grid ul li a .subtext {
        display: none;
    }

    .megamenu-container .p-megamenu-root-list::-webkit-scrollbar {
        display: none !important;
    }

    .p-megamenu[pr_id_1]>.p-megamenu-root-list .p-menuitem-active .p-megamenu-panel {
        position: relative !important;
        left: 0 !important;
        box-shadow: none;
        border-radius: 0;
        background: inherit;
    }

    .megamenu-container .p-megamenu-root-list {
        overflow: auto !important;
        max-height: 27rem !important;
        overflow-y: scroll !important;
        overflow-x: hidden !important;
    }

    .megamenu-container .p-megamenu-root-list::-webkit-scrollbar {
        display: none;
    }

    .p-megamenu-horizontal .p-megamenu-root-list {
        flex-wrap: nowrap !important;
    }

    .p-megamenu-start .logo {
        width: 210px !important;
    }

    .product-dropdown .flex-item-img {
        flex-direction: row !important;
    }

    .product-dropdown .subtext {
        margin-left: 10px;
    }

    .company .border-circle {
        margin-left: 20px;
    }

    .product-dropdown .p-megamenu-submenu {
        height: 9.5rem;
    }

    .product-dropdown .subtext {
        align-items: center !important;
    }
}

@media screen and (min-width: 998px) and (max-height: 1100px) {

    .company .p-megamenu-panel {
        position: absolute !important;
        right: -100% !important;
    }
}

@media screen and (min-width: 1150px) and (max-height: 1310px) {
    .company .p-megamenu-panel {
        position: absolute !important;
        right: -120% !important;
    }
}

@media screen and (min-width: 1334px)and (max-width: 1350px) {
    .product-dropdown .p-megamenu-panel {
        right: -300px !important;
    }
}

@media (min-width: 1080px) and (max-width: 1080px) {
    .product-dropdown .p-megamenu-panel {
        right: -312% !important;
    }
}