.p-accordion-tab a {
    color: var(--accent-color) !important;
    background-color: transparent !important;
    font-size: 14px !important;
}

.p-accordion-tab a svg {
    color: var(--accent-color) !important;
}

.p-accordion {
    color: var(--accent-color) !important;
    margin-bottom: 70px !important;
}

.common-faq .p-accordion {
    color: var(--accent-color) !important;
    margin-bottom: 10px !important;
}

.p-accordion-content,
.p-accordion-content span {
    background-color: transparent !important;
    color: var(--accent-color) !important;
    line-height: 1.9 !important;
}

.p-accordion-content span strong {
    color: rgb(54, 54, 250) !important;
    text-decoration: underline !important;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
}

.faq-header .container-fluid {
    width: 50% !important;
    margin: 0px !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    border: 0px !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    border-radius: 0px !important;
}

.faq-header .light-mode-subtract-bg {
    width: 0px !important;
}

.p-accordion-tab .p-accordion-content {
    border: 0px !important;
    box-shadow: none !important;
    padding-left: 33px !important;
}

.p-accordion-header-link {
    display: flex;
    justify-content: space-between !important;
}

.p-input-icon-right input.p-inputtext.p-component.p-inputtext-sm {
    height: 27px !important;
    background-color: transparent !important;
    width: auto !important;
    color: var(--accent-color) !important;
}

.p-accordion-header-link svg {
    display: none;
}

.p-accordion-header-text {
    display: contents !important;
}

.faq-header {
    margin-top: 6% !important;
    display: flex !important;
    padding-left: 108px;
    padding-top: 15px;
    z-index: 99 !important;
    background-color: transparent !important;
    backdrop-filter: blur(2px) !important;
}

.fixed-top-faqs {
    position: sticky;
    top: 40px;
    left: 0;
    right: 0;
    z-index: 1;
}

.p-accordion-tab {
    margin-bottom: 0px !important;
}

.p-component {
    font-family: Verdana (sans-serif) !important;
}

.copy-message {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 1000;
    opacity: 0.9;
    font-size: 14px;
    text-align: center;
}

.sequence-number {
    margin-bottom: 5px;
}

.faq-scroll {
    height: calc(100vh - 145px) !important;
    overflow: auto !important;
    overflow-x: hidden !important;
}

.common-faq a {
    font-weight: 400 !important;
}

.faq-mobile-fontsize {
    line-height: 2.5 !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding: 0 !important;
}