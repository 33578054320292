.main-content {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 8px;
    backdrop-filter: blur(3px);
}

.product-title {
    display: flex;
    align-items: center;
}

.product-title .icon {
    font-size: 24px;
    margin-right: 10px;
}

.product-title h2 {
    margin: 0;
}

.product-buttons {
    margin-top: 20px;
}

.download-walkthrough-button {
    background-color: transparent;
    border: 1px solid white !important;
    color: white !important;
    padding: 10px 20px;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.download-walkthrough-button:hover {
    background-color: white !important;
    color: black !important;
}

.system-list {
    list-style: none;
    padding: 0;
    line-height: 3.4 !important;
}

.system-list a {
    text-decoration: none;
    color: var(--accent-color) !important;
    font-weight: bold;
    text-wrap: nowrap !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.system-list .link-icon {
    margin-left: 5px;
}

.product-page-background {
    background: var(--background-color) !important;
    border-radius: 10px !important;
    font-family: Verdana (sans-serif) !important;
    position: relative !important;
}

.product-icons {
    width: 30px !important;
    height: 30px !important;
}

.zoom-on-hover {
    transition: transform 0.3s ease;
    transform-origin: left center;
}

.zoom-on-hover:hover {
    transform: scale(1.1);
}

@media(min-width:320px) and (max-width:499px) {
    .system-list a {
        font-size: 12px !important;
    }
}