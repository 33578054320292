.scroll-button {
    display: none;
    position: fixed;
    bottom: 3%;
    right: 1%;
    z-index: 999 !important;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: black;
    color: white;
    box-shadow: 0 2px 10px 1px #b5b5b5;
    cursor: pointer;
    padding: 11px;
    width: 47px;
    height: 47px;
    border-radius: 50%;
}