@media (max-width:498px) {

  .pricing-card-button {
    padding: 2px 8px;
  }

  .pricing-card-button a,
  .pricing-product-title {
    font-size: 9px;
  }

  .pricing-list-table,
  .pricing-list-table tr,
  .pricing-list-table td {
    font-size: 9px !important;
    text-align: left !important;
  }

  .price-product-icon {
    width: 30px !important;
    height: 30px !important;
    padding: 5px !important;
  }

  .product-guide-inner-container {
    height: auto;
  }

  .product-guide-container {
    padding: 5px !important;
  }

  body {
    overflow-x: hidden !important;
  }

  .title p {
    font-size: 15px;
  }

  .button-gradient {
    padding: 10px 35px;
    margin: 1%;
    font-size: 12px;
  }

  .button-gradient:hover {
    font-size: 9px;
  }

  .cookie-row {
    bottom: 9% !important;
    margin: 4% !important;
  }

  .modal-content {
    max-width: 100%;
  }

  .modal-toggle-color {
    height: 100%;
  }

  .cookie-container {
    width: 100% !important;
    padding: 4% !important;
    background: black !important;
  }

  .btn-cookie-custom-allow,
  .btn-cookie-custom-decline {
    font-size: 16px !important;
  }

  .h1-tag-lm,
  .about-us-inner-card-title {
    font-size: 18px !important;
    margin-top: 13px;
  }

  .faq-header {
    margin-top: 18% !important;
  }

  .footer-policy-list ul {
    width: 378px;
  }

  .h2-subtitle-lm,
  .content-subtitle,
  .about-us-inner-card-subtitle {
    font-size: 20px !important;
  }

  .content-subtitle {
    margin-top: 8%;
    line-height: 120%;
  }

  .h5-title-lm {
    font-size: 18px !important;
    line-height: normal !important;
  }

  .p-center-content-lm,
  .p-justify-content-lm,
  .p-content-lm {
    font-size: 12px !important;
    width: 100%;
    text-align: left !important;
  }

  #toggle-theme i {
    margin-right: 70%;
  }

  .footer-policy-list ul li {
    font-size: 11px !important;
  }

  .service-inner-card-content,
  .commitment-span,
  .footer-list ul h5,
  .footer-logo-list span,
  table td,
  table th,
  .span-content-bottom,
  .job-bold-title,
  .job-content,
  .icon-card-span,
  .payment-form label,
  .product-feature-list ul li,
  .about-us-inner-card-content,
  .contact-us-form label {
    font-size: 12px !important;
  }

  .table-th {
    font-size: 11px !important;
  }

  .light-mode-service-subtract-bg {
    height: 660px;
    background-position: top;
    padding-right: 0;
  }

  .dark-mode-footer-subtract-bg,
  .light-mode-footer-subtract-bg {
    background-position: top;
    padding-right: 0;
  }

  .product-footer {
    padding: 0px !important;
    margin: 0px !important;
  }

  .about-us-inner-card-subtitle {
    top: 22% !important;
  }

  .card-body {
    height: 220px;
    width: 100%;
  }

  .footer-list ul li a,
  .footer-policy-list ul li a,
  #copyright li,
  .footer-logo-list ul li {
    font-size: 11px !important;
    line-height: 0px;
  }

  .footer-logo-img {
    height: 26px;
    margin-right: 0px;
  }

  .indian-flag {
    height: 16px;
    margin-top: 7px;
  }

  .mobile-footer-flex {
    display: flex;
  }

  .text-xs {
    font-size: 11px !important;
  }

  .mobile-footer-padding {
    padding-top: 0;
    padding-bottom: 0;
  }

  .footer-ms-img {
    height: 60px !important;
  }

  .setimg {
    text-align: center !important;
  }

  .footer-logo-list {
    margin-left: 5px;
    line-height: 0 !important;
  }

  .about-us-inner-banner-1-img,
  .about-us-inner-2-banner-1-img {
    height: 264px;
  }

  .span-container-bottom {
    top: 0%;
    padding: 132px;
  }

  .span-content-bottom {
    top: 60%;
    margin: 3%;
  }

  .service-inner-card-body {
    height: 360px !important;
  }

  .service-inner-card-container {
    margin: 0%;
  }

  .service-inner-card-title {
    font-size: 20px !important;
    top: 37%;
    line-height: 25px;
  }

  .service-inner-card-content {
    padding: 0px 15px;
    top: 45%;
    text-align: justify;
    line-height: 1.5;
  }

  .service-icon-container-lm {
    margin: 0%;
    margin-bottom: 3%;
  }

  .service-icon-box-lm img {
    height: 30px;
  }

  .service-inner-img-card-container {
    padding: 0%;
  }

  .commitment {
    height: 73px;
  }

  .vision-card-img-1-anim,
  .vision-card-img-2-anim {
    margin: 0;
    padding: 12px;
  }

  .icon-card-span {
    font-size: 13px !important;
    line-height: 174%;
  }

  .about-us-inner-card-body {
    height: 200px;
  }

  .about-us-inner-card-title {
    top: 5%;
  }

  .about-us-inner-card-subtitle {
    top: 22%;
  }

  .about-us-inner-card-content {
    padding: 4%;
    top: 28%;
    line-height: 1.4;
    text-align: justify;
  }

  .carousel-cell {
    height: 300px;
    margin-right: 10px;
  }

  .carousel-cell:before {
    display: block;
    text-align: center;
    content: counter(gallery-cell);
    line-height: 200px;
    font-size: 80px;
  }

  .form-container {
    padding: 9px;
  }

  .form-title {
    font-size: 29px;
  }

  input[type=text] {
    width: 100%;
    padding: 12px 3px;
    font-size: 16px;
  }

  .form-img-container img {
    height: 390px !important;
    width: 100% !important;
  }


  /* Our Team */

  .fixed-top-container {
    top: 64px !important;
  }

  .faq-button {
    font-size: 12px !important;
  }

  .p-tablet {
    padding-top: 8px !important;
  }

  .p-accordion-content,
  .p-accordion-content span {
    line-height: 1.6 !important;
    font-size: 10pt !important;
  }

  .p-component,
  .p-component * {
    line-height: 1.5 !important;
    font-size: 12px !important;
  }

  .p-breadcrumb ul {
    flex-wrap: wrap !important;
  }

  input.p-inputtext.p-component.p-inputtext-sm.w-auto.p-inputtext-sm.w-auto {
    width: 230px !important;
  }

  .pricing-p a {
    font-size: 9px !important;
  }

  .banner-fontsize {
    font-size: 14px !important;
  }

  .contact-btn-flex {
    display: block !important;
  }

  .dark-mode-footer-subtract-bg {
    width: 100%;
  }

  .mobile-disable {
    display: none !important;
  }

  .help-center-mobile-sidebar {
    position: absolute !important;
    background: var(--background-color) !important;
    z-index: 9 !important;
  }

  .microsoft-icon {
    width: 100px !important;
  }

  .product-page-features-background {
    width: 8rem !important;
  }

  .product-page-features-background img {
    width: 30px !important;
  }

  .product-page-features-background p {
    font-size: 12px !important;
  }
}

@media (min-width:499px) and (max-width:767px) {

  .help-center-mobile-sidebar {
    position: absolute !important;
    background: var(--background-color) !important;
    z-index: 9 !important;
  }

  .p-tablet {
    padding-top: 61px !important;
  }

  .all-first-section-margin-top-for-navbar {
    margin-top: 2% !important;
    margin-left: 12px;
    font-size: 26px !important;
  }

  .service-icon-box-lm img {
    height: 50px;
  }

  .button-gradient {
    padding: 15px 61px;
    margin: 1%;
  }

  .btn-cookie-custom-allow,
  .btn-cookie-custom-decline {
    font-size: 16px !important;
  }

  .h1-tag-lm,
  .about-us-inner-card-title {
    font-size: 26px !important;
  }

  .h2-subtitle-lm,
  .about-us-inner-card-subtitle {
    font-size: 26px;
  }

  .content-subtitle {
    font-size: 16px;
    line-height: 9px;
  }

  .h5-title-lm {
    font-size: 30px;
  }

  .p-center-content-lm,
  .p-justify-content-lm,
  .p-content-lm {
    font-size: 16px;
    width: 100%;
  }

  #toggle-theme i {
    margin-left: 5%;
  }

  .footer-policy-list ul li {
    font-size: 12px !important;
  }

  .title p,
  .footer-list ul h5,
  .footer-logo-list span,
  .commitment-span,
  .span-content-bottom,
  table td,
  table th,
  .job-bold-title,
  .contact-us-form label,
  .payment-form label,
  .job-content,
  .icon-card-span,
  .product-feature-list ul li,
  .about-us-inner-card-content,
  .button-gradient {
    font-size: 16px;
  }

  .light-mode-service-subtract-bg,
  .dark-mode-footer-subtract-bg,
  .light-mode-footer-subtract-bg {
    padding-right: 0;
  }

  .footer-list ul li a,
  .footer-policy-list ul li a,
  #copyright li,
  .footer-logo-list ul li {
    font-size: 11px;
    line-height: 0px;
  }

  .footer-logo-img {
    height: 39px;
  }

  .footer-ms-img {
    height: 39px !important;
  }

  .footer-logo-list {
    margin-left: 65px;
  }

  .about-us-inner-banner-1-img,
  .about-us-inner-2-banner-1-img {
    height: 564px;
  }

  .span-container-bottom {
    top: 73.5%;
  }

  .span-content-bottom {
    top: 77%;
  }

  .service-inner-card-body {
    height: 460px;
  }

  .service-inner-card-container {
    margin: 1%;
  }

  .service-inner-img-card-container {
    padding: 0%;
  }

  .commitment {
    height: 93.9px;
  }

  .icon-card-span {
    font-size: 13px;
  }

  .about-us-inner-card-body {
    height: 300px;
  }

  .about-us-inner-card-content {
    padding: 0;
  }

  .form-container {
    height: 812px;
    padding: 9px;
  }

  .form-title {
    font-size: 29px;
  }

  input[type=text] {
    width: 100%;
    padding: 12px 3px;
    font-size: 16px;
  }

  .form-img-container img {
    height: 440px;
    margin-top: 9%;
  }

  .button-gradient:hover {
    font-size: 16px;
  }

  .footer-logo-box {
    margin: 0% !important;
  }

  .fixed-top-faqs {
    top: 60px;
  }
}

@media (min-width :768px) and (max-width:991px) {

  .product-guide-inner-container {
    margin-top: 3%;
  }

  .button-gradient {
    padding: 15px 61px;
  }

  .btn-cookie-custom-allow,
  .btn-cookie-custom-decline {
    font-size: 14px !important;
  }

  .h2-subtitle-lm,
  .about-us-inner-card-subtitle {
    font-size: 24px;
  }

  .content-subtitle {
    font-size: 22px;
  }

  .h5-title-lm {
    font-size: 28px;
  }

  .p-center-content-lm,
  .p-justify-content-lm,
  .p-content-lm {
    font-size: 16px;
    width: 100%;
    text-align: justify;
    line-height: 1.5 !important;
  }

  .span-content-bottom {
    line-height: 1.5 !important;
  }

  #toggle-theme i {
    margin-right: 65%;
  }

  .footer-policy-list ul li {
    font-size: 12px !important;
  }

  .title p,
  .footer-list ul h5,
  .footer-logo-list span,
  .commitment-span,
  .span-content-bottom,
  table td,
  table th,
  .commitment-span,
  .job-bold-title,
  .contact-us-form label,
  .payment-form label,
  .job-content,
  .icon-card-span,
  .product-feature-list ul li,
  .about-us-inner-card-content,
  .button-gradient {
    font-size: 16px;
  }

  .light-mode-service-subtract-bg,
  .dark-mode-footer-subtract-bg,
  .light-mode-footer-subtract-bg {
    padding-right: 0;
  }

  .footer-list ul li a,
  .footer-policy-list ul li a,
  #copyright li,
  .footer-logo-list ul li {
    font-size: 10px;
    line-height: normal !important;
  }

  .footer-logo-img {
    height: 34px;
  }

  .indian-flag {
    height: 26px;
  }

  .footer-ms-img {
    height: 70px !important;
  }

  .about-us-inner-banner-1-img,
  .about-us-inner-2-banner-1-img {
    height: 564px;
  }

  .span-container-bottom {
    top: 73.5%;
  }

  .span-content-bottom {
    top: 76%;
  }

  .card-icon-vector {
    height: 70px;
  }

  .service-inner-card-title {
    padding: 1px;
    font-size: 23px;
    top: 27%;
    line-height: 99%;
  }

  .service-inner-card-body {
    height: 410px !important;
  }

  .service-inner-card-container {
    margin: 0%;
  }

  .service-inner-card-container div {
    padding: 1%;
  }

  .service-inner-card-content {
    padding: 4%;
    top: 36%;
    line-height: 1.5;
    text-align: justify;
  }

  .service-inner-img-card-container {
    margin: 0% !important;
    padding: 0 2% !important;
  }

  .commitment {
    height: 44px;
  }

  .commitment-span {
    top: 84%;
  }

  .icon-card-span {
    font-size: 13px;
    line-height: 1.3;
    padding: 1% 2%;
  }

  .about-us-inner-card-body {
    height: 380px !important;
  }

  .about-us-inner-card-content {
    padding: 4%;
    text-align: justify;
    font-size: 18px !important;
    top: 30%;
  }

  .service-icon-box-lm {
    height: 150px;
  }

  .about-us-inner-card-subtitle {
    top: 20% !important;
    font-size: 20px !important;
    font-weight: bold !important;
  }

  .form-container {
    padding: 9px;
  }

  .form-title {
    font-size: 29px;
  }

  input[type=text] {
    width: 100%;
    padding: 12px 3px;
    font-size: 16px;
  }

  .form-img-container img {
    height: 428px !important;
    margin-top: 0%;
    width: 322px !important;
  }

  .first-section-margin-top-for-navbar {
    margin-top: 14% !important;
  }

  .button-gradient:hover {
    font-size: 16px;
  }

  #description-container {
    width: 97% !important;
  }

  .footer-logo-list ul li .prime-icon {
    font-size: 11px;
    margin: 0px 0px 0 0;
  }

  .common-faq .p-accordion {
    margin-top: 0 !important;
  }

  .h1-tag-lm,
  .about-us-inner-card-title {
    font-size: 25px !important;
    margin-top: 21px;
  }

  .h1-heading {
    margin-top: 0px !important;
  }

  .fixed-top-container {
    top: 64px !important;
  }

  .footer-logo-box {
    margin: 0% !important;
  }

  .text-lg {
    font-size: 17px !important;
  }

  .footer-policy-list ul {
    width: 342px !important;
  }

  .faq-header {
    margin-top: 12% !important;
    padding-left: 78px;
    padding-top: 35px;
  }

  .all-first-section-margin-top-for-navbar {
    margin-top: 1% !important;
    margin-left: 20px;
    font-size: 24px;
  }

  .banner-container {
    height: 100vh !important;
  }

  .description-font-size {
    font-family: var(--bs-font-Verdana (sans-serif)) !important;
  }
}

@media (min-width: 1020px) and (max-width: 1199px) {

  #description-container {
    width: 96% !important;
  }

  .footer-logo-list ul li .prime-icon {
    font-size: 11px;
    margin: 0px 0px 0 0;
  }

  .fixed-top-container {
    top: 69px;
  }

  .product-know-button a {
    font-size: 14px !important;
  }

  .first-section-margin-top-for-navbar {
    margin-top: 9% !important;
  }

  .form-img-container img {
    height: 512px !important;
  }

  .banner-container {
    height: 100vh;
  }

}

@media (min-width:1020px) and (max-width:1199px) {

  .btn-cookie-custom-allow,
  .btn-cookie-custom-decline {
    font-size: 16px !important;
  }

  .button-gradient {
    height: 40px;
    margin-top: 3%;
    padding: 6px 40px;
    font-size: 14px
  }

  .m-1::before {
    top: 1px;
  }

  .button-gradient:hover {
    font-size: 14px !important;
  }

  .h1-tag-lm {
    font-size: 30px;
    margin-top: 12px;
  }

  .table td a {
    text-align: none !important;
  }

  .h2-subtitle-lm {
    font-size: 18px;
  }

  .content-subtitle {
    font-size: 25px;
  }

  .h5-title-lm {
    font-size: 22px;
  }

  .p-center-content-lm,
  .p-justify-content-lm,
  .p-content-lm {
    font-size: 14px;
    margin-top: 2% !important;
    width: 100%;
    text-align: justify;
  }

  .title p,
  .footer-policy-list ul li,
  .footer-list ul h5,
  .footer-logo-list span,
  .span-content-bottom,
  .commitment-span,
  table td,
  table th,
  .job-bold-title,
  .contact-us-form label,
  .payment-form label,
  .job-content,
  .icon-card-span,
  .product-feature-list ul li,
  .about-us-inner-card-content,
  .button-gradient {
    font-size: 16px;
  }

  .light-mode-service-subtract-bg,
  .light-mode-footer-subtract-bg {
    height: 520px;
  }

  .card-body {
    height: 226px;
    width: 420px;
  }

  .footer-list ul li a {
    font-size: 12px;
    line-height: 28px;
  }

  .footer-logo-img {
    height: 30px;
  }

  .footer-ms-img {
    height: 70px !important;
  }

  .footer-logo-list ul li a img {
    height: 45px;
  }

  .footer-policy-list ul li a,
  #copyright li,
  .footer-logo-list ul li {
    font-size: 12px;
  }

  .about-us-inner-banner-1-img,
  .about-us-inner-2-banner-1-img {
    height: 350px;
  }

  .span-container-bottom {
    top: 71.6%;
    padding: 50px;
  }

  .span-content-bottom {
    top: 77%;
  }

  .service-icon-container-lm {
    margin: 1%;
  }

  .service-inner-card-body {
    height: 350px !important;
    margin: 1%;
  }

  .service-inner-card-container {
    margin: 0;
  }

  .card-icon-vector {
    top: 6%;
    left: 40%;
    height: 73px;
  }

  .service-inner-card-title {
    font-size: 18px;
    top: 27%;
    padding: 0 2px;
  }

  .service-inner-card-content {
    font-size: 12px;
    top: 40%;
  }

  .commitment {
    height: 59.9px;
    top: 78%;
  }

  .commitment-span {
    top: 82%;
  }

  .icon-card-span {
    font-size: 14px;
  }

  .about-us-inner-card-body {
    height: 383px !important;
  }

  .about-us-inner-card-title {
    font-size: 34px;
    top: 2% !important;
  }

  .about-us-inner-card-subtitle {
    font-size: 18px;
    top: 15% !important;
  }

  .about-us-inner-card-content {
    font-size: 14px !important;
    text-align: justify;
    top: 25%;
  }

  .form-container {
    height: 800px;
    padding: 20px 10px;
  }

  .form-title {
    font-size: 23px;
  }

  input[type=text] {
    width: 100%;
    padding: 10px 0px;
    font-size: 14px;
  }

  .form-img-container img {
    height: 506px;
  }

  .carousel-cell {
    height: 310px;
    margin-right: 10px;
  }

  .text-lg {
    font-size: 12px !important;
  }

}

@media (min-width:1200px) and (max-width:1280px) {

  .btn-cookie-custom-allow,
  .btn-cookie-custom-decline {
    font-size: 16px !important;
  }

  .button-gradient {
    height: 50px;
    margin-top: 3%;
    padding: 12px 61px;
  }

  .h1-tag-lm {
    font-size: 35px;
  }

  .h2-subtitle-lm,
  .content-subtitle {
    font-size: 20px;
  }

  .h5-title-lm {
    font-size: 26px;
  }

  .p-center-content-lm,
  .p-justify-content-lm,
  .p-content-lm {
    font-size: 16px;
    margin-top: 2% !important;
    width: 100%;
  }

  .title p,
  .footer-policy-list ul li,
  .footer-list ul h5,
  .footer-logo-list span,
  .span-content-bottom,
  .commitment-span,
  .job-bold-title,
  table td,
  table th,
  .contact-us-form label,
  .payment-form label,
  .job-content,
  .icon-card-span,
  .product-feature-list ul li,
  .about-us-inner-card-content,
  .button-gradient {
    font-size: 14px;
  }

  .light-mode-service-subtract-bg,
  .light-mode-footer-subtract-bg {
    height: 526px;
  }

  .card-body {
    height: 226px;
    width: 420px;
  }

  .footer-list ul li a {
    font-size: 14px;
    line-height: 30px;
  }

  .footer-logo-img {
    height: 50px;
  }

  .footer-ms-img {
    height: 50px !important;
  }

  .footer-logo-list ul li a img {
    height: 55px;
  }

  .footer-policy-list ul li a,
  #copyright li,
  .footer-logo-list ul li {
    font-size: 14px;
  }

  .about-us-inner-banner-1-img,
  .about-us-inner-2-banner-1-img {
    height: 442px;
  }

  .span-container-bottom {
    top: 70.6%;
    padding: 65px;
  }

  .span-content-bottom {
    top: 77%;
  }

  .service-inner-card-body {
    height: 400px;
    margin: 1% 0 5%;
  }

  .service-inner-card-container {
    margin: 0;
  }

  .card-icon-vector {
    top: 6%;
    left: 40%;
    height: 73px;
  }

  .service-inner-card-title {
    font-size: 23px;
    top: 27%;
  }

  .service-inner-card-content {
    font-size: 14px;
    top: 45%;
  }

  .commitment {
    height: 70.9px;
  }

  .commitment-span {
    top: 85%;
  }

  .icon-card-span {
    font-size: 16px;
  }

  .about-us-inner-card-body {
    height: 282px;
  }

  .about-us-inner-card-title {
    font-size: 44px;
  }

  .about-us-inner-card-subtitle {
    font-size: 20px;
    margin-top: 2%;
  }

  .form-container {
    height: 900px;
  }

  .form-title {
    font-size: 29px;
  }

  .contact-us-form label,
  .payment-form label {
    font-size: 16px;
  }

  input[type=text] {
    width: 100%;
    padding: 12px 3px;
    font-size: 16px;
  }

  .form-img-container img {
    height: 546px;
  }

  .button-gradient:hover {
    font-size: 16px;
  }

  .carousel-cell {
    height: 370px;
  }

}

@media (min-width: 1281px) and (max-width:1440px) {

  .btn-cookie-custom-allow,
  .btn-cookie-custom-decline {
    font-size: 16px !important;
  }

  .title p,
  .footer-list ul h5,
  .footer-policy-list ul li,
  .footer-logo-list span,
  .span-content-bottom,
  .commitment-span,
  table td,
  table th,
  .job-bold-title,
  .contact-us-form label,
  .payment-form label,
  .job-content,
  .icon-card-span,
  .product-feature-list ul li,
  .about-us-inner-card-content,
  .button-gradient {
    font-size: 16px;
  }

  .button-gradient {
    height: 50px;
    padding: 12px 61px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2%;
  }

  p {
    margin-top: 2%;
  }

  .h1-tag-lm {
    font-size: 35px;
  }

  .h2-subtitle-lm,
  .content-subtitle {
    font-size: 20px;
  }

  .h5-title-lm {
    font-size: 26px;
  }

  .p-center-content-lm,
  .p-justify-content-lm,
  .p-content-lm {
    font-size: 16px;
    margin-top: 2% !important;
    width: 100%;
    text-align: justify;
  }

  .light-mode-service-subtract-bg,
  .light-mode-footer-subtract-bg {
    height: 526px;
  }

  .card-body {
    height: 226px;
    width: 420px;
  }

  .footer-list ul li a {
    font-size: 14px;
    line-height: 30px;
  }

  .footer-logo-img {
    height: 50px;
  }

  .footer-ms-img {
    height: 85px !important;
  }

  .footer-logo-list ul li a img {
    height: 55px;
  }

  .footer-policy-list {
    margin-top: 1%;
  }

  .footer-policy-list ul li a,
  #copyright li,
  .footer-logo-list ul li {
    font-size: 14px;
  }

  .about-us-inner-banner-1-img,
  .about-us-inner-2-banner-1-img {
    height: 442px;
  }

  .span-container-bottom {
    top: 70.6%;
    padding: 65px;
  }

  .span-content-bottom {
    top: 77%;
  }

  .service-inner-card-body {
    height: 420px;
    margin: 1%;
  }

  .card-icon-vector {
    top: 6%;
    left: 40%;
    height: 73px;
  }

  .service-inner-card-title {
    font-size: 22px;
    line-height: 30px;
    top: 29%;
  }

  .service-inner-card-content {
    font-size: 14px;
    top: 46%;
  }

  .commitment {
    height: 75.9px;
    top: 80%;
  }

  .about-us-inner-card-title {
    font-size: 44px;
    top: 18%;
  }

  .about-us-inner-card-subtitle {
    font-size: 20px;
    margin-top: 2%;
    top: 37%;
  }

  .form-container {
    height: 965px;
  }

  .form-title {
    font-size: 29px;
  }

  input[type=text] {
    width: 100%;
    padding: 12px 3px;
    font-size: 16px;
  }

  .form-img-container img {
    height: 546px;
  }

  .button-gradient:hover {
    font-size: 16px;
  }

  .carousel-cell {
    height: 370px;
  }

  .text-lg {
    font-size: 12px !important;
  }

  .h1-tag-lm {
    font-size: 1.75rem;
    margin-top: 27px !important;
  }

  .about-us-inner-banner-1-img {
    margin-top: 0% !important;
  }

  .fixed-top-container {
    top: 68px !important;
  }

  .pricing-list-table {
    margin-top: 112px !important;
  }

  .product-know-button a {
    font-size: 14px !important;
  }
}

@media (min-width:1441px) and (max-width:1680px) {

  .btn-cookie-custom-allow,
  .btn-cookie-custom-decline {
    font-size: 16px !important;
  }

  .h1-tag-lm {
    font-size: 26px !important;
    margin-top: 8px !important;
  }

  .h1-heading {
    margin-top: 0 !important;
  }

  .h2-subtitle-lm,
  .content-subtitle {
    font-size: 26px;
  }

  .h5-title-lm {
    font-size: 30px;
  }

  .p-center-content-lm,
  .p-justify-content-lm,
  .p-content-lm {
    width: 100%;
  }

  .title p,
  .footer-policy-list ul li,
  .footer-list ul h5,
  .footer-logo-list span,
  .span-content-bottom,
  .commitment-span,
  table th,
  .job-bold-title,
  .contact-us-form label,
  .payment-form label,
  .job-content,
  .icon-card-span,
  .product-feature-list ul li,
  .about-us-inner-card-content,
  .button-gradient {
    text-align: justify;
    font-size: 14px;
  }

  table td {
    font-size: 14px;
  }

  .about-us-inner-banner-1-img,
  .about-us-inner-2-banner-1-img {
    height: 564px !important;
  }

  .span-container-bottom {
    top: 73.5%;
  }

  .span-content-bottom {
    top: 77%;
  }

  .service-inner-card-body {
    height: 460px;
  }

  .service-inner-card-container {
    margin: 1% !important;
  }

  .icon-card-span {
    font-size: 18px !important;
  }

  .about-us-inner-card-body {
    height: 328px;
  }

  .about-us-inner-card-title {
    top: 5%;
  }

  .about-us-inner-card-subtitle {
    top: 24% !important;
  }
}

@media (min-width:1681px) {

  .h1-tag-lm {
    font-weight: 700 !important;
    font-size: 32px;
  }
}

@media (min-width:1280px) and (max-width:1534px) {
  .fixed-top-container {
    position: sticky;
    top: 67px;
  }

  .pricing-list-table {
    margin-top: 151px;
  }

  .faq-header {
    position: sticky;
    padding-left: 114px;
    height: 87px;
    top: 61px !important;
  }
}

@media (min-width: 1536px) {
  .h1-tag-lm {
    margin-top: 21px;
  }

  .fixed-top-faqs {
    position: sticky;
    top: 62px !important;
  }

  .btn-width {
    width: 11rem !important;
  }

  .fixed-top-container {
    position: sticky;
    top: 70px;
  }

  .faq-header {
    position: sticky;
    top: 67px !important;
    padding-top: 0px;
  }

  .h1-heading {
    margin-top: 12px;
  }
}

@media (min-width: 1920px) {
  .fixed-top-faqs {
    position: sticky;
    top: 8%;
  }

  .faq-header {
    margin-top: 5% !important;
  }

  .h1-heading {
    margin-top: 20px;
  }

  .title-show {
    margin-top: 94px;
  }

  .faq-scroll {
    height: 950px !important;
  }
}