.service-inner-card-body {
    position: relative;
    padding: 40px;
    width: 100%;
    height: 500px !important;
    background: linear-gradient(180deg, #000000 0%, #121318 100%);
    box-shadow: 0px 11.8109px 53.1492px rgb(0 0 0 / 35%);
    border-radius: 14.7637px;
    border: 1.18px solid rgb(255 255 255 / 10%);
}

.card-line-vector {
    position: absolute;
    top: 0.3%;
    width: 100%;
}

.service-inner-card-title {
    font-weight: 700;
    color: var(--fixed-color);
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    position: absolute;
    top: 33%;
    padding: 0 13px;
}

.service-inner-card-content {
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    color: #a8abb4;
    position: absolute;
    top: 50%;
    right: 1%;
    padding: 0 30px;
}

.service-inner-img-card-container {
    margin: 1% !important;
    padding: 0 8% !important;
}

.vision-card-img-1-anim,
.vision-card-img-2-anim {
    margin: 0;
    padding: 0;
}

.service-inner-img-card-body {
    width: 100%;
    margin-bottom: 6%;
    position: relative;
}

.commitment-image-card {
    position: relative;
    width: 100%;
}

.commitment-image-card:hover {
    opacity: 0.8;
    cursor: pointer;
}

.commitment {
    top: 81%;
    position: absolute;
    background: var(--fixed-background-color);
    width: 100%;
    color: var(--fixed-color);
    opacity: 0.5;
    height: 88.9px;
}

.commitment-span {
    font-weight: 700;
    font-size: 25px;
    line-height: 34px;
    color: var(--fixed-color);
    position: absolute;
    top: 87%;
}

.card-icon-vector {
    position: absolute;
    top: 10%;
}

/* Full banner container */
.our-commitment-banner-container {
    position: relative;
    width: 100%;
    height: 70vh;
    overflow: hidden;
}

.our-commitment-banner-image {
    background-image: url("../../../assets/images/commitment/commitment-background.jpeg");
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.our-commitment-banner-text-overlay {
    position: absolute;
    bottom: 0%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 20px;
    text-align: center;
    border-radius: 5px;
}

.our-commitment-banner-text {
    font-size: 1.2rem;
    line-height: 1.5;
    font-weight: 300;
}