.payment-page-support h5,
.payment-page-support a {
    color: var(--accent-color) !important;
}

.payment-page-product-select {
    width: 100% !important;
    background: none !important;
}

.no-hover {
    pointer-events: none;
    opacity: 0.5;
}

.payment-progress-spinner {
    position: fixed !important;
    z-index: 111 !important;
    width: 100vw !important;
    display: flex !important;
    align-items: center !important;
    background-color: var(--background-color) !important;
    opacity: 0.7 !important;
    height: 100vh !important;
}

.payment-form {
    padding: 2%;
    color: var(--accent-color) !important;
}

.payment-form label {
    font-weight: 500;
    font-size: 16px !important;
    line-height: 130%;
    color: var(--accent-color) !important;
}